<script>
export default {
  props: {
    centroResultado: { type: Object },
  },
  data() {
    return {
        
    }
  },
}
</script>

<template>
<div class="centroResultado">
  <p>ID: {{ centroResultado.id }}</p>
  <p>Conta pai: {{ centroResultado.woner_id }}</p>
  <p>Conta: {{ centroResultado.conta }}</p>
  <p>Nome do Centro de Custo: {{ centroResultado.identificacao }}</p>
  <p>Ativo: <b-badge data-name="status" class="field-status" v-bind:variant="(centroResultado.status) ? 'success' : 'danger'">{{ (centroResultado.status) ? 'Sim' : 'Não' }}</b-badge></p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ centroResultado.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ centroResultado.updated_at }}</b-badge></p>
</div>
</template>